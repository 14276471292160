/* eslint-disable no-alert */
import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Link } from 'gatsby'

import useVerifyRole from '../../hooks/verifyRole'
import Hero from '../../components/Hero'
import Section from '../../components/Section'
import Button from '../../components/Button'
import SEO from '../../components/SEO'

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])

  const { data: { invoices = [] } = {} } = useQuery(gql`
    query Invoiced {
      invoices(status: invoiced) {
        id
        name
        status
        amount
      }
    }
  `)

  const [updateInvoiceStatus] = useMutation(gql`
    mutation UpdateInvoiceStatus($id: Int!, $status: PaymentStatus!) {
      updateInvoiceStatus(id: $id, status: $status) {
        success
        invoice {
          id
          name
          status
          amount
        }
      }
    }
  `)

  if (loading || !hasRole) {
    return null
  }

  const statusUpdateHandler = (id, status) => () => {
    if (
      status === 'cancelled' &&
      !window.confirm(`Er du sikker på at du vil kansellere fakturaen?

Dette kan ikke endres, gavekort på denne fakturaen blir trukket tilbake og brukere som har brukt gavekort mister sin tilgang.`)
    ) {
      return
    }

    updateInvoiceStatus({ variables: { id: Number(id), status } })
  }

  return (
    <>
      <SEO title="Fakturaer" />
      <Hero title={<><Link to="/admin">Admin</Link> &gt; Fakturaer</>} type={['center', 'small']} />
      <Section size="small">
        <div style={{ minHeight: 'calc(100vh - 48.3rem)' }}>
          <p>
            Nedenfor ser du fakturaer som er fakturert, men som ikke er merket
            som betalt eller ikke betalt.
          </p>

          <ul
            style={{
              borderTop: '2px solid var(--color-highlighted)',
              marginTop: '1rem',
            }}
          >
            {invoices
              .filter(({ status }) => status === 'invoiced')
              .map(invoice => (
                <li
                  key={invoice.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid var(--color-highlighted)',
                    padding: '10px 0',
                  }}
                >
                  <span>Faktura {invoice.id}</span>
                  <span>{invoice.amount / 100}kr</span>

                  <span>
                    <Button
                      onClick={statusUpdateHandler(invoice.id, 'paid')}
                      small
                      spaceless
                      primary
                    >
                      Betalt
                    </Button>{' '}
                    <Button
                      onClick={statusUpdateHandler(invoice.id, 'cancelled')}
                      small
                      spaceless
                      primary
                    >
                      Ikke betalt
                    </Button>
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </Section>
    </>
  )
}
